

<template>
  <div>
    <AccessPolicySelectorModal :show.sync="selectorShow" @ok="selectorOk" :multiple="false" />
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'LocationEditor',
  components: {
    AccessPolicySelectorModal: () => import('@/components/modal/AccessPolicySelectorModal')
  },
  data() {
    return {
      value: '',
      selectorShow: true
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.stopEditing();
      }, 100);
    },
    selectorOk({ details }) {
      const newAccessPolicy = details.map(i => { return { uuId: i.uuId, name: i.name}});

      if (newAccessPolicy.length === 0) {
        this.value = null;
        this.closePicker();
      }
      this.value = newAccessPolicy[0];
      this.closePicker();
    }
  },

  created() {
    this.value = this.params.value;
  }
})
</script>

<style lang="scss" scoped>
.edit-cell {
  background-color: var(--white);
}

.number-input {
  width: 100%;
  height: 100%;
  padding: 1px 2px;
  outline: none;
  border: none;
}

</style>