var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AccessPolicySelectorModal", {
        attrs: { show: _vm.selectorShow, multiple: false },
        on: {
          "update:show": function ($event) {
            _vm.selectorShow = $event
          },
          ok: _vm.selectorOk,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }