var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%", width: "100%" },
      attrs: { id: _vm.elemId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "xl",
            title: _vm.selectorTitleLabel,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "modal-class": [_vm.elemId],
            "no-close-on-esc": _vm.isCellEditing || _vm.editorShow,
            "content-class": "shadow",
          },
          on: { hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selectorShow,
            callback: function ($$v) {
              _vm.selectorShow = $$v
            },
            expression: "selectorShow",
          },
        },
        [
          _vm.alertObj.msg != null
            ? _c("AlertFeedback", {
                attrs: {
                  msg: _vm.alertObj.msg,
                  details: _vm.alertObj.msgDetails.list,
                  detailTitle: _vm.alertObj.msgDetails.title,
                  alertState: _vm.alertObj.state,
                },
                on: { resetAlert: _vm.resetAlert },
              })
            : _vm._e(),
          _vm.allowManage && _vm.entity != null
            ? _c(
                "PriorityNavigation",
                {
                  ref: "generic-selector-grid-toolbar",
                  staticClass: "grid-toolbar border",
                  attrs: {
                    dropDownStayOpened: _vm.badgeFilterModalOpened != "close",
                    closeDropdown: _vm.closePriorityNavDropdown,
                  },
                  on: {
                    "update:closeDropdown": function ($event) {
                      _vm.closePriorityNavDropdown = $event
                    },
                    "update:close-dropdown": function ($event) {
                      _vm.closePriorityNavDropdown = $event
                    },
                  },
                  nativeOn: _vm._d({}, [
                    _vm.priorityNavMouseOverEvent,
                    function ($event) {
                      return _vm.onPriorityNavMouseOverOrTouchEnd.apply(
                        null,
                        arguments
                      )
                    },
                    _vm.priorityNavTouchEndEvent,
                    function ($event) {
                      return _vm.onPriorityNavMouseOverOrTouchEnd.apply(
                        null,
                        arguments
                      )
                    },
                  ]),
                },
                [
                  _vm.canAdd(_vm.entity)
                    ? _c(
                        "li",
                        [
                          _c(
                            "span",
                            { attrs: { id: "BTN_ADD_" + _vm.elemId } },
                            [
                              _c(
                                "b-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openEditor(true)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    style: {
                                      color: "var(--grid-toolbar-button)",
                                    },
                                    attrs: { icon: ["far", "plus"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "BTN_ADD_" + _vm.elemId,
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("button.add")) + " ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canView(_vm.entity)
                    ? _c(
                        "li",
                        [
                          _c(
                            "span",
                            { attrs: { id: "BTN_EDIT_" + _vm.elemId } },
                            [
                              _c(
                                "b-btn",
                                {
                                  attrs: { disabled: _vm.disableEdit },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openEditor(false)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "pen-to-square"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "BTN_EDIT_" + _vm.elemId,
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("button.edit")) + " ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canAdd(_vm.entity) && _vm.entity != "WEBHOOK"
                    ? _c(
                        "li",
                        [
                          _c(
                            "span",
                            { attrs: { id: "BTN_DUPLICATE_" + _vm.elemId } },
                            [
                              _c(
                                "b-btn",
                                {
                                  attrs: { disabled: _vm.disableDuplicate },
                                  on: { click: _vm.showDuplicateDialog },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "clone"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "BTN_DUPLICATE_" + _vm.elemId,
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("button.duplicate")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.entity == "TAG" &&
                  _vm.canDelete(_vm.entity) &&
                  _vm.canAdd(_vm.entity)
                    ? _c(
                        "li",
                        [
                          _c(
                            "span",
                            { attrs: { id: "BTN_MERGE_TAG_" + _vm.elemId } },
                            [
                              _c(
                                "b-btn",
                                { on: { click: _vm.mergeTag } },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "merge"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "BTN_MERGE_TAG_" + _vm.elemId,
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("tag.button.merge")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canDelete(_vm.entity)
                    ? _c(
                        "li",
                        [
                          _c(
                            "span",
                            { attrs: { id: "BTN_DELETE_" + _vm.elemId } },
                            [
                              _c(
                                "b-btn",
                                {
                                  attrs: { disabled: _vm.disableDelete },
                                  on: { click: _vm.rowDelete },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "trash-can"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "BTN_DELETE_" + _vm.elemId,
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("button.delete")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.entity != "USER" &&
                  _vm.entity != "ACCESS_POLICY" &&
                  _vm.entity != "WEBHOOK"
                    ? _c(
                        "li",
                        [
                          _vm.canAdd(_vm.entity)
                            ? [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      id: "BTN_IMPORT_DOCUMENT_" + _vm.elemId,
                                    },
                                  },
                                  [
                                    _c(
                                      "b-btn",
                                      { on: { click: _vm.fileImport } },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "inbox-in"] },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target:
                                        "BTN_IMPORT_DOCUMENT_" + _vm.elemId,
                                      triggers: "hover",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "entity_selector." +
                                              _vm.formattedEntity +
                                              "_import_document"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              attrs: {
                                id: "BTN_EXPORT_DOCUMENT_" + _vm.elemId,
                              },
                            },
                            [
                              _c(
                                "b-btn",
                                { on: { click: _vm.fileExport } },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "inbox-out"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "BTN_EXPORT_DOCUMENT_" + _vm.elemId,
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "entity_selector." +
                                        _vm.formattedEntity +
                                        "_export_document"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.entity != "WEBHOOK" && _vm.canView(_vm.entity)
                    ? _c(
                        "li",
                        {
                          on: _vm._d({ mouseleave: _vm.onFilterLeave }, [
                            _vm.filterMouseEnterEvent,
                            _vm.onFilterOver,
                          ]),
                        },
                        [
                          _c(
                            "b-dropdown",
                            {
                              ref: "filter",
                              staticClass: "action-bar-dropdown text-filter",
                              attrs: {
                                id: "BTN_FILTER_" + _vm.elemId,
                                "toggle-class": "text-decoration-none",
                                "no-caret": "",
                              },
                              on: { hide: _vm.onFilterTextDropdownHide },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c("font-awesome-icon", {
                                          class:
                                            _vm.filterText !== ""
                                              ? "active"
                                              : "",
                                          attrs: {
                                            icon: [
                                              "far",
                                              "file-magnifying-glass",
                                            ],
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3499512239
                              ),
                            },
                            [
                              _c(
                                "b-dropdown-form",
                                {
                                  staticClass: "filter-padding",
                                  on: {
                                    submit: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "search-input" },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          placeholder: _vm.$t("task.filter"),
                                        },
                                        on: {
                                          focus: function ($event) {
                                            _vm.filterTextFocus = true
                                          },
                                          blur: function ($event) {
                                            _vm.filterTextFocus = false
                                          },
                                          keypress: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.onFilterSubmit.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.filterText,
                                          callback: function ($$v) {
                                            _vm.filterText = $$v
                                          },
                                          expression: "filterText",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass: "search-append",
                                              attrs: {
                                                size: "sm",
                                                variant: "danger",
                                              },
                                              on: { click: _vm.onFilterClear },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass: "search-clear",
                                                attrs: {
                                                  icon: ["far", "xmark"],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass:
                                                "search-append search-append-bg",
                                              attrs: { size: "sm" },
                                              on: { click: _vm.onFilterSubmit },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  variant: "secondary",
                                                  icon: [
                                                    "far",
                                                    "magnifying-glass",
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.entity != "WEBHOOK" && _vm.canView(_vm.entity)
                    ? _c(
                        "li",
                        {
                          on: _vm._d({}, [
                            _vm.badgeFilterMouseEnterEvent,
                            _vm.onBadgeFilterEnter,
                            _vm.badgeFilterMouseOverEvent,
                            _vm.onBadgeFilterOver,
                            _vm.badgeFilterMouseLeaveEvent,
                            _vm.onBadgeFilterLeave,
                            _vm.badgeFilterTouchEndEvent,
                            _vm.onBadgeFilterOver,
                          ]),
                        },
                        [
                          _c(
                            "b-dropdown",
                            {
                              ref: "badgeFilter",
                              staticClass: "action-bar-dropdown text-filter",
                              attrs: {
                                id: "BTN_BADGE_FILTER_" + _vm.elemId,
                                "toggle-class": "text-decoration-none",
                                "no-caret": "",
                              },
                              on: { hide: _vm.onBadgeFilterDropdownHide },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c("font-awesome-icon", {
                                          class:
                                            _vm.badgeFilters != null &&
                                            _vm.badgeFilters.length > 0
                                              ? "active"
                                              : "",
                                          attrs: { icon: ["far", "filter"] },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1473832823
                              ),
                            },
                            [
                              _c(
                                "b-dropdown-form",
                                {
                                  staticClass: "filter-padding",
                                  on: {
                                    submit: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c("TaskViewBadgeFilter", {
                                    attrs: {
                                      filters: _vm.badgeFilters,
                                      fields: _vm.badgeFilterFields,
                                      fieldValues: _vm.badgeFilterFieldValues,
                                    },
                                    on: {
                                      modified: _vm.onBadgeFilterModified,
                                      filterModalOpened:
                                        _vm.onBadgeFilterModalOpened,
                                      filterModalClosed:
                                        _vm.onBadgeFilterModalClosed,
                                      fetchFieldOptions:
                                        _vm.onBadgeFilterFetchOptions,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canSyncLdap(_vm.entity) &&
                  _vm.$store.state.data.status.ldapEnabled &&
                  _vm.entity === "USER"
                    ? _c(
                        "li",
                        [
                          _c(
                            "span",
                            { attrs: { id: "BTN_SYNC_" + _vm.elemId } },
                            [
                              _c(
                                "b-btn",
                                { on: { click: _vm.syncLdap } },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "down-to-bracket"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "BTN_SYNC_" + _vm.elemId,
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("button.sync_ldap")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.entity != "WEBHOOK"
                    ? _c(
                        "li",
                        {
                          staticClass: "view",
                          on: _vm._d({ mouseleave: _vm.onViewLeave }, [
                            _vm.viewMouseEnterEvent,
                            _vm.onViewOver,
                          ]),
                        },
                        [
                          _c(
                            "b-dropdown",
                            {
                              ref: "view",
                              staticClass: "action-bar-dropdown",
                              attrs: {
                                id: "BTN_VIEW_" + _vm.elemId,
                                "toggle-class": "text-decoration-none",
                                "no-caret": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "desktop"] },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                4186387599
                              ),
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: { click: _vm.showAllColumns },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("task.button.all_columns")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: { click: _vm.showNoColumns },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.button.no_columns"))
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("b-dropdown-divider"),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: { click: _vm.savePreset },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "action-item-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            (_vm.entity
                                              ? _vm.entity ==
                                                "TEMPLATE__PROJECT"
                                                ? "template" + "."
                                                : _vm.formattedEntity + "."
                                              : "") + "button.save_view"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("b-dropdown-divider"),
                              _vm._l(_vm.filteredViews, function (item, index) {
                                return [
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      key: index,
                                      staticClass: "action-item",
                                      attrs: {
                                        disabled: _vm.inProgressShow,
                                        href: "#",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.loadViewSettings(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "action-item-label-with-icon",
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _c(
                                        "span",
                                        [
                                          !_vm.editPermission(item)
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "action-item-icon",
                                                    attrs: {
                                                      id:
                                                        "COPY_COLUMN_" + index,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.copyColumnSettings(
                                                          item.name,
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "copy"],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        "COPY_COLUMN_" + index,
                                                      triggers: "hover",
                                                      placement: "top",
                                                      boundary: "viewport",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            (_vm.entity
                                                              ? _vm.entity ==
                                                                "TEMPLATE__PROJECT"
                                                                ? "template" +
                                                                  "."
                                                                : _vm.formattedEntity +
                                                                  "."
                                                              : "") +
                                                              "button.copy_view"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "action-item-icon position-third",
                                                    attrs: {
                                                      id: "BTN_INFO_" + index,
                                                    },
                                                    on: _vm._d(
                                                      {
                                                        mouseleave: function (
                                                          $event
                                                        ) {
                                                          return _vm.onInfoLeave(
                                                            index
                                                          )
                                                        },
                                                      },
                                                      [
                                                        _vm.infoMouseEnterEvent,
                                                        function ($event) {
                                                          return _vm.onInfoOver(
                                                            index
                                                          )
                                                        },
                                                      ]
                                                    ),
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: [
                                                          "far",
                                                          "circle-info",
                                                        ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("b-popover", {
                                                  ref: "BTN_INFO_" + index,
                                                  refInFor: true,
                                                  attrs: {
                                                    target: "BTN_INFO_" + index,
                                                    show: _vm.showInfo[index],
                                                    placement: "top",
                                                    boundary: "viewport",
                                                    triggers: "manual",
                                                    content: _vm.$t("owner", [
                                                      _vm.views[index].owner,
                                                    ]),
                                                  },
                                                  on: {
                                                    "update:show": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.showInfo,
                                                        index,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          _vm.editPermission(item)
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "action-item-icon position-third",
                                                    attrs: {
                                                      id:
                                                        "SHARE_COLUMN_" + index,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.shareColumnSettings(
                                                          index,
                                                          item.name,
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: [
                                                          item.defaultView
                                                            ? "fas"
                                                            : "far",
                                                          "share-nodes",
                                                        ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        "SHARE_COLUMN_" + index,
                                                      triggers: "hover",
                                                      placement: "top",
                                                      boundary: "viewport",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            (_vm.entity
                                                              ? _vm.entity ==
                                                                "TEMPLATE__PROJECT"
                                                                ? "template" +
                                                                  "."
                                                                : _vm.formattedEntity +
                                                                  "."
                                                              : "") +
                                                              "button.share_view"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                          _vm.editPermission(item)
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "action-item-icon position-second",
                                                    attrs: {
                                                      id:
                                                        "UPDATE_COLUMN_" +
                                                        index,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.updateColumnSettings(
                                                          index,
                                                          item.name,
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: [
                                                          "far",
                                                          "floppy-disk",
                                                        ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        "UPDATE_COLUMN_" +
                                                        index,
                                                      triggers: "hover",
                                                      placement: "top",
                                                      boundary: "viewport",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            (_vm.entity
                                                              ? _vm.entity ==
                                                                "TEMPLATE__PROJECT"
                                                                ? "template" +
                                                                  "."
                                                                : _vm.formattedEntity +
                                                                  "."
                                                              : "") +
                                                              "button.update_view"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                          _vm.editPermission(item)
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "action-item-icon",
                                                    attrs: {
                                                      id:
                                                        "REMOVE_COLUMN_" +
                                                        index,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.removeColumnSettings(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: [
                                                          "far",
                                                          "trash-can",
                                                        ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        "REMOVE_COLUMN_" +
                                                        index,
                                                      triggers: "hover",
                                                      placement: "top",
                                                      boundary: "viewport",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            (_vm.entity
                                                              ? _vm.entity ==
                                                                "TEMPLATE__PROJECT"
                                                                ? "template" +
                                                                  "."
                                                                : _vm.formattedEntity +
                                                                  "."
                                                              : "") +
                                                              "button.delete_view"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.entity != "ACCESS_POLICY" &&
                  _vm.entity != "TAG" &&
                  _vm.entity != "WEBHOOK"
                    ? [
                        _c(
                          "li",
                          {
                            on: _vm._d({ mouseleave: _vm.onColoringLeave }, [
                              _vm.colorMouseEnterEvent,
                              _vm.onColoringOver,
                            ]),
                          },
                          [
                            _c(
                              "b-dropdown",
                              {
                                ref: "coloring",
                                staticClass: "action-bar-dropdown",
                                attrs: {
                                  id: "BTN_COLORING_" + _vm.elemId,
                                  "toggle-class": "text-decoration-none",
                                  "no-caret": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function () {
                                        return [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: ["far", "palette"] },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1244906976
                                ),
                              },
                              [
                                _c(
                                  "b-dropdown-group",
                                  { attrs: { header: _vm.$t("colorby") } },
                                  [
                                    _vm._l(
                                      _vm.coloring,
                                      function (value, name, index) {
                                        return [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              key: index,
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onColorChange(name)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "action-item-label",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "entity_selector.coloring_" +
                                                          name
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.coloring[name]
                                                ? _c("font-awesome-icon", {
                                                    staticClass: "active-check",
                                                    attrs: {
                                                      icon: ["far", "check"],
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _c("ag-grid-vue", {
            staticClass:
              "spreadsheet ag-theme-balham generic-selector selector-grid-height",
            staticStyle: { width: "100%" },
            attrs: {
              id: "rebate-grid",
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columnDefs,
              context: _vm.context,
              defaultColDef: _vm.defaultColDef,
              getRowId: function (params) {
                return params.data.uuId
              },
              pagination: "",
              paginationPageSize: 1000,
              cacheBlockSize: 10000,
              rowModelType: "serverSide",
              rowMultiSelectWithClick: false,
              rowSelection: "multiple",
              serverSideInfiniteScroll: true,
              sideBar: false,
              suppressContextMenu: "",
              suppressDragLeaveHidesColumns: "",
              suppressCellFocus: false,
              singleClickEdit: false,
              suppressMultiSort: "",
              enableRangeSelection: true,
              suppressRowClickSelection: "",
              enableFillHandle: true,
              fillOperation: _vm.fillOperation,
              fillHandleDirection: "xy",
              processCellForClipboard: _vm.processCellForClipboard,
              processCellFromClipboard: _vm.processCellFromClipboard,
              suppressClipboardApi: "",
              navigateToNextCell: _vm.navigateToNextCell,
              tabToNextCell: _vm.tabToNextCell,
              noRowsOverlayComponent: "noRowsOverlay",
              noRowsOverlayComponentParams: _vm.noRowsOverlayComponentParams,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              enableCellEditingOnBackspace: "",
            },
            on: {
              "grid-ready": _vm.onGridReady,
              "cell-key-down": _vm.onCellKeyDown,
              "paste-start": _vm.onPasteStart,
              "paste-end": _vm.onPasteEnd,
              "cell-focused": _vm.cellFocused,
              "cell-editing-started": _vm.onCellEditingStarted,
              "cell-editing-stopped": _vm.onCellEditingStopped,
            },
          }),
        ],
        1
      ),
      _vm.currentEditorComponent != null
        ? _c(_vm.currentEditorComponent, {
            tag: "component",
            attrs: {
              show: _vm.editorShow,
              id: _vm.entityId,
              title: _vm.editorTitleLabel,
            },
            on: {
              "update:show": function ($event) {
                _vm.editorShow = $event
              },
              success: _vm.editorSuccess,
            },
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.duplicateTitleLabel,
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { hidden: _vm.duplicateCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.duplicateInProgress
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: "",
                            size: "sm",
                            variant: "success",
                          },
                        },
                        [
                          _c("b-spinner", {
                            attrs: { small: "", type: "grow" },
                          }),
                          _vm._v(_vm._s(_vm.$t("button.processing"))),
                        ],
                        1
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.duplicateOk },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.duplicate")))]
                      ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.duplicateShow,
            callback: function ($$v) {
              _vm.duplicateShow = $$v
            },
            expression: "duplicateShow",
          },
        },
        [
          _vm.entity == "USER"
            ? [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("user.field.email"),
                      "label-for": "duplicateEmail",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "duplicateEmail",
                            type: "text",
                            "data-vv-as": _vm.$t("user.field.email"),
                            "data-vv-name": "duplicateEmail",
                            "data-vv-delay": "500",
                          },
                          model: {
                            value: _vm.duplicateEmail,
                            callback: function ($$v) {
                              _vm.duplicateEmail = $$v
                            },
                            expression: "duplicateEmail",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-invalid-feedback",
                      {
                        staticClass: "alert-danger form-field-alert",
                        class: { "d-block": _vm.showDuplicateEmailError },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-exclamation"] },
                        }),
                        _vm._v(
                          "  " +
                            _vm._s(_vm.errors.first("duplicateEmail")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("user.field.firstName"),
                      "label-for": "duplicateFirstname",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: { id: "duplicateFirstname", type: "text" },
                          model: {
                            value: _vm.duplicateFirstname,
                            callback: function ($$v) {
                              _vm.duplicateFirstname = $$v
                            },
                            expression: "duplicateFirstname",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("user.field.lastName"),
                      "label-for": "duplicatLastname",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "duplicatLastname",
                            type: "text",
                            "data-vv-as": _vm.$t("user.field.lastName"),
                            "data-vv-name": "duplicateLastname",
                            "data-vv-delay": "500",
                          },
                          model: {
                            value: _vm.duplicateLastname,
                            callback: function ($$v) {
                              _vm.duplicateLastname = $$v
                            },
                            expression: "duplicateLastname",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-invalid-feedback",
                      {
                        staticClass: "alert-danger form-field-alert",
                        class: { "d-block": _vm.showDuplicateLastnameError },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-exclamation"] },
                        }),
                        _vm._v(
                          "  " +
                            _vm._s(_vm.errors.first("duplicateLastname")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm.entity == "WEBHOOK"
            ? [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("webhook.field.entity"),
                      "label-for": "duplicateEntityField",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "duplicateEntityField",
                            type: "text",
                            "data-vv-as": _vm.$t("webhook.field.entity"),
                            "data-vv-name": "duplicateEntityField",
                            "data-vv-delay": "500",
                          },
                          model: {
                            value: _vm.duplicateEntityField,
                            callback: function ($$v) {
                              _vm.duplicateEntityField = $$v
                            },
                            expression: "duplicateEntityField",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-invalid-feedback",
                      {
                        staticClass: "alert-danger form-field-alert",
                        class: { "d-block": _vm.showDuplicateEntityError },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-exclamation"] },
                        }),
                        _vm._v(
                          "  " +
                            _vm._s(_vm.errors.first("duplicateEntityField")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("webhook.field.action"),
                      "label-for": "duplicateAction",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "duplicateAction",
                            type: "text",
                            "data-vv-as": _vm.$t("webhook.field.action"),
                            "data-vv-name": "duplicateAction",
                            "data-vv-delay": "500",
                          },
                          model: {
                            value: _vm.duplicateAction,
                            callback: function ($$v) {
                              _vm.duplicateAction = $$v
                            },
                            expression: "duplicateAction",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-invalid-feedback",
                      {
                        staticClass: "alert-danger form-field-alert",
                        class: { "d-block": _vm.showDuplicateActionError },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-exclamation"] },
                        }),
                        _vm._v(
                          "  " +
                            _vm._s(_vm.errors.first("duplicateAction")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("webhook.field.url"),
                      "label-for": "duplicateUrl",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "duplicateUrl",
                            type: "text",
                            "data-vv-as": _vm.$t("webhook.field.url"),
                            "data-vv-name": "duplicateUrl",
                            "data-vv-delay": "500",
                          },
                          model: {
                            value: _vm.duplicateUrl,
                            callback: function ($$v) {
                              _vm.duplicateUrl = $$v
                            },
                            expression: "duplicateUrl",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-invalid-feedback",
                      {
                        staticClass: "alert-danger form-field-alert",
                        class: { "d-block": _vm.showDuplicateUrlError },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-exclamation"] },
                        }),
                        _vm._v(
                          "  " + _vm._s(_vm.errors.first("duplicateUrl")) + " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.duplicateFieldNameLabel,
                      "label-for": "name",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "duplicateName",
                            type: "text",
                            "data-vv-as": _vm.duplicateFieldNameLabel,
                            "data-vv-name": "duplicate.name",
                            "data-vv-delay": "500",
                            trim: "",
                          },
                          model: {
                            value: _vm.duplicateName,
                            callback: function ($$v) {
                              _vm.duplicateName = $$v
                            },
                            expression: "duplicateName",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.entity == "PROJECT"
                      ? _c(
                          "b-input-group",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                model: {
                                  value: _vm.includeTasks,
                                  callback: function ($$v) {
                                    _vm.includeTasks = $$v
                                  },
                                  expression: "includeTasks",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("project.duplicate_tasks"))
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-form-invalid-feedback",
                      {
                        staticClass: "alert-danger form-field-alert",
                        class: { "d-block": _vm.showDuplicateNameError },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-exclamation"] },
                        }),
                        _vm._v(
                          "  " +
                            _vm._s(_vm.errors.first("duplicate.name")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm.entity != "USER" && _vm.entity != "ACCESS_POLICY"
        ? [
            _vm.entity != null
              ? _c("GanttImportDialog", {
                  attrs: {
                    properties: _vm.docImportProperties,
                    mode: _vm.ganttImportMode,
                    show: _vm.docImportShow,
                    existingData: _vm.existingData,
                    title: _vm.$t(
                      "entity_selector." +
                        _vm.formattedEntity +
                        "_import_document"
                    ),
                  },
                  on: {
                    "modal-ok": _vm.docImportOk,
                    "modal-cancel": _vm.docImportCancel,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.ecTitle,
            "content-class": "entity-delete-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.ecConfirmDeleteShow = false
            },
            ok: _vm.ecConfirmDeleteOk,
            cancel: _vm.ecConfirmDeleteCancel,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.entityCol.cantDeleteDetails == null
                    ? [
                        _vm.ecShowApplyAllCheckbox
                          ? _c(
                              "b-form-checkbox",
                              {
                                staticClass: "apply-to-all",
                                model: {
                                  value: _vm.entityCol.applyAll,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.entityCol, "applyAll", $$v)
                                  },
                                  expression: "entityCol.applyAll",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                            )
                          : _vm._e(),
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "success" },
                            on: {
                              click: function ($event) {
                                return ok()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "danger" },
                            on: {
                              click: function ($event) {
                                return cancel()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                        ),
                      ]
                    : [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "success" },
                            on: {
                              click: function ($event) {
                                return ok()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        ),
                      ],
                ]
              },
            },
          ]),
          model: {
            value: _vm.ecConfirmDeleteShow,
            callback: function ($$v) {
              _vm.ecConfirmDeleteShow = $$v
            },
            expression: "ecConfirmDeleteShow",
          },
        },
        [_c("p", [_vm._v(_vm._s(_vm.ecConfirmDeleteStatement))])]
      ),
      _vm.entity === "LOCATION"
        ? _c(
            "b-modal",
            {
              attrs: {
                title: _vm.$t("location.confirmation.title_delete"),
                "content-class": "shadow",
                "no-close-on-backdrop": "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-footer",
                    fn: function (ref) {
                      var cancel = ref.cancel
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "success" },
                            on: {
                              click: function ($event) {
                                return cancel()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.close")))]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                318145534
              ),
              model: {
                value: _vm.cannotDeleteModalShow,
                callback: function ($$v) {
                  _vm.cannotDeleteModalShow = $$v
                },
                expression: "cannotDeleteModalShow",
              },
            },
            [
              _c("div", { staticClass: "d-block" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("location.error.location_in_use")) + " "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.entity == "TAG"
        ? [
            _c(
              "b-modal",
              {
                attrs: {
                  title: _vm.$t("tag.merge_title"),
                  "content-class": "shadow",
                  "no-close-on-backdrop": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "modal-footer",
                      fn: function (ref) {
                        var cancel = ref.cancel
                        return [
                          _vm.duplicateInProgress
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    disabled: "",
                                    size: "sm",
                                    variant: "success",
                                  },
                                },
                                [
                                  _c("b-spinner", {
                                    attrs: { small: "", type: "grow" },
                                  }),
                                  _vm._v(_vm._s(_vm.$t("button.processing"))),
                                ],
                                1
                              )
                            : _c(
                                "b-button",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.mergeFrom === null ||
                                      _vm.mergeTo === null ||
                                      _vm.mergeFrom === _vm.mergeTo,
                                    size: "sm",
                                    variant: "success",
                                  },
                                  on: { click: _vm.mergeOk },
                                },
                                [_vm._v(_vm._s(_vm.$t("tag.button.merge")))]
                              ),
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "danger" },
                              on: {
                                click: function ($event) {
                                  return cancel()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2134629270
                ),
                model: {
                  value: _vm.mergeTagShow,
                  callback: function ($$v) {
                    _vm.mergeTagShow = $$v
                  },
                  expression: "mergeTagShow",
                },
              },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("tag.merge_from"),
                      "label-for": "name",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-select", {
                          attrs: { options: _vm.mergeOptions },
                          model: {
                            value: _vm.mergeFrom,
                            callback: function ($$v) {
                              _vm.mergeFrom = $$v
                            },
                            expression: "mergeFrom",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("tag.merge_to"),
                      "label-for": "name",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-select", {
                          attrs: { options: _vm.mergeOptions },
                          model: {
                            value: _vm.mergeTo,
                            callback: function ($$v) {
                              _vm.mergeTo = $$v
                            },
                            expression: "mergeTo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptSaveShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.save"),
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptSaveShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptShareShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.share"),
          sharing: true,
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptShareShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteViewOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteViewShow,
            callback: function ($$v) {
              _vm.confirmDeleteViewShow = $$v
            },
            expression: "confirmDeleteViewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("task.confirmation.delete_view")) + " "),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }